import uk from "../../assets/images/uk.png"
import france from "../../assets/images/france.png"
import india from "../../assets/images/india.png"
import america from "../../assets/images/america.png"
import autria from "../../assets/images/autria.png"
import bllogo from "../../assets/images/bllogo.png"
import arrigt from "../../assets/images/arrigt.png"
import visa from "../../assets/images/visa.png"
import { Link, NavLink, useLocation } from 'react-router-dom';
import { toast } from "react-toastify"
import { sendAppLinkMail } from "../../utils/commonApi"
import { useEffect, useRef, useState } from "react"
import MainLoader from "../mainLoader"
import { getCountries } from "./api"

interface FooterContainerProps {
  styles: any
}
const FooterContainer: React.FC<FooterContainerProps> = ({ styles }) => {

  const [emailSendError, setEmailSendError] = useState("");
  const [popularCountries, setPopularCountries] = useState<any>("");
  const [loader, setloader] = useState(false);
  const formRef = useRef<any>(null);

  const location = useLocation();
  const getRoot = window.location.origin;

  useEffect(() => {
    const getPopularContries = async () => {
      setPopularCountries((await getCountries())?.data?.countries)
    }
    getPopularContries()
  }, [])

  const handleSendMailSubmit = (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setEmailSendError("");
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;

    if (!email) {
      setEmailSendError("Email is required!");
      return null;
    }
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailRegex.test(email)) {
      setEmailSendError("Invalid email!");
      return null;
    }

    setloader(true);
    toast.promise(sendAppLinkMail({ email }), {
      pending: {
        render() {
          return "Trying to send email";
        },
      },
      success: {
        render({ data }) {
          setloader(false);
          formRef.current.reset();
          return "Email successfully sent!";
        },
      },
      error: {
        render({ data }: any) {
          setloader(false);
          return data.data.message;
        },
      },
    });
  };
  return (
    <div className='row'>
      <div className='col-md-3'>
        <div className={styles.footerInner}>
          <h6>About</h6>
          <div className={styles.ftLogo}>
            <Link to='/'><img src={bllogo} alt="" /></Link>
          </div>
          <p><a style={{ color: "#000" }} href={`Tel:+447452292014`}>+44 7452 292014</a> <br />
            <a style={{ color: "#000" }} href={`mailto:hello@commbitz.com`}>hello@commbitz.com</a><br />
            COMMBITZ for IoT & M2M<br />
            COMMBITZ for Business<br />
            COMMBITZ for Virtual Numbers</p>
        </div>
      </div>
      <div className='col-md-2'>
        <div className={styles.footerInner}>
          <h6>Popular Countries</h6>
          <ul>
            {popularCountries && popularCountries.map((country: any, index: any) => (
              <li key={index + 1}>
                <Link to={location.pathname == "/esim" ? `?country=${country?._id}` : `${getRoot}/esim?country=${country?._id}`}>
                  <img src={country?.flagImageUrl} alt="" />
                  {country?.name}
                </Link>
              </li>
            ))}

            {/* <li><Link to="#">  <img src={france} alt="" />France</Link></li>
            <li><Link to="#">  <img src={india} alt="" />India</Link></li>
            <li><Link to="#">  <img src={america} alt="" />America</Link></li>
            <li><Link to="#">  <img src={autria} alt="" />Australia</Link></li> */}
          </ul>
        </div>
      </div>
      <div className='col-md-2'>
        <div className={styles.footerInner}>
          <h6>More Info</h6>
          <ul>
            {/* <li><NavLink to="#"><img src={arrigt} alt="" />Help Center</NavLink></li> */}
            <li><NavLink to="/contact"><img src={arrigt} alt="" />Contact</NavLink></li>
            <li>
            <a className="nav-Link" href="https://commbitz.com/blog/"><img src={arrigt} alt="" />Blogs</a>
            </li>
            {/* <li><NavLink to="#"><img src={arrigt} alt="" />Referral</NavLink></li>
            <li><NavLink to="#"><img src={arrigt} alt="" />Sitemap</NavLink></li> */}
          </ul>
        </div>
      </div>
      <div className='col-md-2'>
        {/* <div className={styles.footerInner}>
          <h6>Partner with Us</h6>
          <ul>
            <li><Link to="#"><img src={arrigt} alt="" />Commbitz Partner Hub</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />Affiliate Partners</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />Distribution Partners</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />API Partners</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />Commbitz for</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />Corporates</Link></li>
            <li><Link to="#"><img src={arrigt} alt="" />Partner Login</Link></li>
          </ul>
        </div> */}
      </div>

      <div className='col-md-3'>
        <div className={styles.footerInner}>
          <div className={styles.Newsletter}>
            <span>Straight from our desk, to your inbox</span>
            <div className={styles.formGroup}>
              <form onSubmit={handleSendMailSubmit} ref={formRef}>
                <input type="text" name="email" placeholder="Your Email" />
                <input type="submit" value="Subscribe" />
              </form>
              {emailSendError && (
                <span className="error">{emailSendError}</span>
              )}
            </div>
          </div>
          {/* <h6>Payment Channels</h6>
          <div className='Visa'>
            <img src={visa} alt="" />
          </div> */}
        </div>
      </div>
      {loader && <MainLoader />}
    </div>
  )
}

export default FooterContainer