import logo from "../../assets/images/logo.png"
import { Link, NavLink, useLocation } from 'react-router-dom';
import useAuth from "../../lib/hooks/useAuth";
import styles from './style.module.css';
import { useState } from "react";


const NavBar = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [toggle, setToggle] = useState(false)

  const isActiveRoute = (route: string) => {
    return location.pathname === route;
  };

  return (
    <div className={styles.menuHead}>
      <div className="container">
        <nav className="navbar navbar-expand-lg pt-0">
          <div className="container-fluid p-0">
            <Link className="navbar-brand" to="/"><img src={logo} alt="Commbitz logo" /></Link>
            <button className="navbar-toggler" type="button" onClick={!toggle ? () => setToggle(true) : () => setToggle(false)} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={toggle ? { display: "block" } : { display: "none" }}>
              <ul className={styles.navbar}>
                <li className={isActiveRoute('/') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/">Home</NavLink>
                </li>
                <li className={isActiveRoute('/about') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/about">About Us</NavLink>
                </li>
                <li className={isActiveRoute('/esim') ? styles.active : ''}>
                  <NavLink className={`nav-Link`} to="/esim">eSIM</NavLink>
                </li>
                <li className={isActiveRoute('/blog') || isActiveRoute('/blogdetail') ? styles.active : ''}>
                  <a className="nav-Link" href="https://commbitz.com/blog/">Blogs</a>
                </li>
                <li className={isActiveRoute('/faq') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/faq">FAQ’s</NavLink>
                </li>
                <li className={isActiveRoute('/troubleshoot') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/troubleshoot">Troubleshooting</NavLink>
                </li>
                <li className={isActiveRoute('/features') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/features">Features</NavLink>
                </li>
                <li className={isActiveRoute('/contact') ? styles.active : ''}>
                  <NavLink className="nav-Link" to="/contact">Contact Us</NavLink>
                </li>
              </ul>
            </div>
            <div className={styles.loginBtn}>
              {isAuthenticated ? <Link to="/profile">Profile</Link> : <Link to="/login">Signup / Login</Link>}
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default NavBar